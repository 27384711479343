<template>
  <modal-pro></modal-pro>
  <section class="section-full banner">
    <div class="box">
      <img src="./../../assets/banner-cc.png" alt="banner-cc" class="banner-img"  />
      <div class="banner-sep"></div>
      <h1 v-html="$t('cc.title1')"></h1>
    </div>
    <scroll-down></scroll-down>
  </section>
  <product v-for="(item, idx) in products" :key="idx" :product="item"></product>
  <product-range product="CC"></product-range>
</template>

<script>
import ScrollDown from "./../../components/ScrollDown.vue";
import ProductRange from "./../../components/ProductsRange.vue";
import Product from "./../../components/Product.vue";
import ModalPro from "./../../components/ModalPro.vue";

export default {
  name: "CC",
  components : { ScrollDown, ProductRange, Product, ModalPro},
  computed:  {
    products() {
      return [
        {
          img : [
            require("@/assets/products/Walnut.png")
          ],
          bg : require("@/assets/products/walnut_bg.jpg"),
          name : "<span class='colored'>W</span>alnut & <span class='colored'>S</span>ooil",
          special :  true,
          video : null,
          bullets : [
            this.$t("walnut.bullet1"),
            this.$t("walnut.bullet2"),
            this.$t("walnut.bullet3"),
            this.$t("walnut.bullet4")
          ]
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">

h1 {
  color:#fff;
  font-size:30px;
  font-weight:400;
  text-align: center;
  line-height:1.5em;
  margin:0;
}

@media screen and (max-width:768px) {
  h1 {
    font-size:20px;
  }
}

</style>